export const URLS = {
  login: '/users/login',
  usersPassword: '/users/password',
  user: '/user',
  getAccessList: '/users/getAccessList',
  changePassword: '/users/change_password',
  entity: '/entity',
  producttypes: '/producttypes',
  assettypes: '/assettypes',
  clients: '/clients',
  clinet: '/client',
  buyers: '/buyers',
  buyer: '/buyer',
  sellers: '/sellers',
  seller: '/seller',
  address: '/address',
  partners: '/partners',
  order: '/order',
  recordingJuisdictions: '/recording_juisdictions',
  documentType: '/document_type',
  orderSearchSection: '/search',
  toggleBookmarkStatus: '/toggleBookmarkStatus',
  intereststypes: '/intereststypes',
  executetypes: '/executetypes',
  chainoftitletypes: '/chainoftitletypes',
  lienstypes: '/lienstypes',
  requirementstypes: '/requirementstypes',
  exceptiontypes: '/exceptiontypes',
  jurisdictionstypes: '/jurisdictionstypes',
  saveTypeExam: '/saveTypeExam',
  updateTypeExam: '/updateTypeExam',
  typeExam: '/typeExam',
  taskTitles: '/taskTitles',
  subTaskTitles: '/subTaskTitles',
  tasksList: 'tasksList',
  tasks: '/tasks',
  teamsName: '/teamsName',
  members: '/members',
  taskMembers: '/taskMembers',
  comments: '/comments',
  roles: '/roles',
  peoples: '/peoples',
  people: '/people',
  team: '/team',
  invitation: '/invitation',
  generateDocument: '/generate_document',
  get_document: '/get_document',
  update_document: '/update_document',
  searchTask: '/searchTask',
  activityComments: '/activityComments',
  mergePdf: '/mergePdf',
  documentsTypes: '/documentsTypes',
  documents: '/documents',
  updateDocument: '/updateDocument',
  deleteDocument: '/deleteDocument',
  deliverables: '/deliverables',
  milestone: '/milestone',
  getTaskStatistics: '/getTaskStatistics',
  taskPreviewSortList: '/taskPreviewSortList',
  taskPreviewFilterList: '/taskPreviewFilterList',
  taskPreviewAssigneeList: '/taskPreviewAssigneeList',
  getTaskCompleted: '/getTaskCompleted',
  dashboardTasks: '/dashboardTasks',
  getProjectNamesList: '/getProjectNamesList',
  getProjectNamesStats: '/getProjectNamesStats',
  partnerProfile: '/partnerProfile',
  partnerProfiles: '/partnerProfiles',
  partnerProfileTypes: '/partnerProfileTypes',
  partnerContact: '/partnerContact',
  partnerContacts: '/partnerContacts',
  partnerCommunications: '/partnerCommunications',
  purchaseOrderOptions: '/purchaseOrderOptions',
  emailSubjectFormat: '/emailSubjectFormat',
  clientDetail: '/clientDetail',
  partnerComments: 'partnerComments',
  productFee: '/productFee',
  orderEnable: '/orderEnable',
  orderCancel: '/orderCancel',
  orderPreviewSortList: '/orderPreviewSortList',
  orderPreviewFilterList: '/orderPreviewFilterList',
  clientOrderNumbers: '/clientOrderNumbers',
  orderDashboard: '/orderDashboard',
  getVisits: '/getVisits',
  saveVisit: '/saveVisit',
  openLienType: '/openLienType',
  miscsType: '/miscsType',
  leaseType: '/leaseType',
  openMortgageType: '/openMortgageType',
  toogleBookmarkStatusOpenLien: '/toogleBookmarkStatusOpenLien',
  toogleBookmarkStatusMiscs: '/toogleBookmarkStatusMiscs',
  toogleBookmarkStatusLease: '/toogleBookmarkStatusLease',
  toogleBookmarkStatusOpenMortgage: '/toogleBookmarkStatusOpenMortgage',
  getZipCodeAutofill: '/getZipCodeAutofill',
  autofillAddress: '/autofillAddress',
  orderDetailsTabList: '/orderDetailsTabList',
  getClientMap: '/getClientMap',
  getPreviewDeliverables: '/getPreviewDeliverables',
  siteNames: '/siteNames',
  storage: '/storage'
};
