import axios from 'axios';
import { AppConfig } from './config';
import { URLS } from '../constants/urls';
let userAuthToken = '';

const api = axios.create({
  baseURL: AppConfig.apiBaseUrl
});

export const setUserAuthToken = (token) => {
  userAuthToken = token;
};

// Fetching from localstorage
const getAuthToken = async () => {
  const sessionData = await JSON.parse(
    window.sessionStorage.getItem(`authorization`)
  );

  return sessionData?.token ?? '';
};

api.interceptors.request.use(
  async (config) => {
    const { needsToken } = config.headers;

    if (needsToken) {
      if (!userAuthToken) {
        userAuthToken = await getAuthToken();
      }

      if (userAuthToken) {
        config.headers['Authorization'] = 'Token ' + userAuthToken;
      }
    }

    // REMOVE ME
    config.headers['ngrok-skip-browser-warning'] = '69420';

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (
      error?.response?.status === 401 &&
      error?.response?.data?.error === 'Session expired'
    ) {
      const authenticationError = new CustomEvent('authError', {
        detail: error
      });
      window.dispatchEvent(authenticationError);
    }
    return Promise.reject(error);
  }
);

export const login = (email, pw) => {
  return api.post(
    URLS.login,
    {
      user: {
        email: email,
        password: pw
      }
    },
    {
      headers: {
        needsToken: false
      }
    }
  );
};

export const resetPassword = (email) => {
  return api.post(
    URLS.usersPassword,
    {
      user: {
        email: email
      }
    },
    {
      headers: {
        needsToken: false
      }
    }
  );
};

export const setPassword = (passwordData) => {
  return api.put(URLS.usersPassword, passwordData, {
    headers: {
      needsToken: false
    }
  });
};

export const getUser = () => {
  return api.get(URLS.user, {
    headers: {
      needsToken: true
    }
  });
};

export const updateUser = (userData) => {
  return api.put(URLS.user, userData, {
    headers: {
      needsToken: true
    }
  });
};

export const getAccessList = () => {
  return api.get(URLS.getAccessList, {
    headers: {
      needsToken: true
    }
  });
};

export const changePassword = (passwordData) => {
  return api.put(URLS.changePassword, passwordData, {
    headers: {
      needsToken: true
    }
  });
};

export const getSignedURL = (fileKey) => {
  return api.post(
    URLS.storage,
    { key: fileKey },
    {
      headers: {
        needsToken: true
      }
    }
  );
};

export const getEntity = () => {
  return api.get(URLS.entity, {
    headers: {
      needsToken: true
    }
  });
};

export const getProduct = (clientID) => {
  return api.get(
    `${URLS.producttypes}${clientID ? `?clientID=${clientID}` : ''}`,
    {
      headers: {
        needsToken: true
      }
    }
  );
};

export const getAssetsTypes = (limit = 100) => {
  return api.get(URLS.assettypes, {
    params: {
      limit: limit
    },
    headers: {
      needsToken: true
    }
  });
};

export const getClinets = (searchQuery, all = true) => {
  return api.get(
    `${URLS.clients}?all=${all}${searchQuery ? '&query=' + searchQuery : ''}`,
    {
      headers: {
        needsToken: true
      }
    }
  );
};

export const getDocumentType = () => {
  return api.get(URLS.documentType, {
    headers: {
      needsToken: true
    }
  });
};

export const createClient = (clinetData) => {
  return api.post(URLS.clinet, clinetData, {
    headers: {
      needsToken: true
    }
  });
};

export const updateClient = (clinetData) => {
  return api.put(URLS.clinet, clinetData, {
    headers: {
      needsToken: true
    }
  });
};

export const getBuyers = (searchQuery) => {
  return api.get(
    `${URLS.buyers}${searchQuery ? '?query=' + searchQuery : ''}`,
    {
      headers: {
        needsToken: true
      }
    }
  );
};

export const createBuyer = (buyerData) => {
  return api.post(URLS.buyer, buyerData, {
    headers: {
      needsToken: true
    }
  });
};

export const updateBuyer = (buyerData) => {
  return api.put(URLS.buyer, buyerData, {
    headers: {
      needsToken: true
    }
  });
};

export const getSellers = (searchQuery) => {
  return api.get(
    `${URLS.sellers}${searchQuery ? '?query=' + searchQuery : ''}`,
    {
      headers: {
        needsToken: true
      }
    }
  );
};

export const createSeller = (sellerData) => {
  return api.post(URLS.seller, sellerData, {
    headers: {
      needsToken: true
    }
  });
};

export const updateSeller = (sellerData) => {
  return api.put(URLS.seller, sellerData, {
    headers: {
      needsToken: true
    }
  });
};

export const addAddress = (addressData, withLocation = false) => {
  return api.post(
    URLS.address,
    { ...addressData, withLocation: withLocation },
    {
      headers: {
        needsToken: true
      }
    }
  );
};

export const updateAddress = (addressData, withLocation = false) => {
  return api.put(
    URLS.address,
    { ...addressData, withLocation: withLocation },
    {
      headers: {
        needsToken: true
      }
    }
  );
};

export const getSearcher = (searchQuery) => {
  return api.get(
    `${URLS.partners}${searchQuery ? '?query=' + searchQuery : ''}`,
    {
      headers: {
        needsToken: true
      }
    }
  );
};

export const createNewOrder = (orderData) => {
  return api.post(URLS.order, orderData, {
    headers: {
      needsToken: true
    }
  });
};

export const updateOrder = (orderID, orderData) => {
  return api.put(`${URLS.order}?id=${orderID}`, orderData, {
    headers: {
      needsToken: true
    }
  });
};

export const getOrderByID = (orderID) => {
  return api.get(`${URLS.order}?id=${orderID}`, {
    headers: {
      needsToken: true
    }
  });
};

export const getRecordingJuisdictions = (searchQuery) => {
  return api.get(
    `${URLS.recordingJuisdictions}${
      searchQuery ? '?query=' + searchQuery : ''
    }`,
    {
      headers: {
        needsToken: true
      }
    }
  );
};

export const getOrderSearchSectionById = (orderID) => {
  return api.get(`${URLS.orderSearchSection}?id=${orderID}`, {
    headers: {
      needsToken: true
    }
  });
};

export const saveOrderSearch = (searchPayload) => {
  return api.post(`${URLS.orderSearchSection}`, searchPayload, {
    headers: {
      needsToken: true
    }
  });
};

export const updateOrderSearch = (searchPayload) => {
  return api.put(`${URLS.orderSearchSection}`, searchPayload, {
    headers: {
      needsToken: true
    }
  });
};

export const toggleBookmarkStatus = (name, isBookmarked) => {
  return api.put(
    URLS.toggleBookmarkStatus,
    {
      name: name,
      bookmark: isBookmarked
    },
    {
      headers: {
        needsToken: true
      }
    }
  );
};

export const getInterestsTypes = () => {
  return api.get(URLS.intereststypes, {
    headers: {
      needsToken: true
    }
  });
};

export const getExecuteTypes = () => {
  return api.get(URLS.executetypes, {
    headers: {
      needsToken: true
    }
  });
};

export const bookmarkInterestsTypes = (name, isBookmarked) => {
  return api.put(
    URLS.intereststypes,
    {
      name: name,
      bookmark: isBookmarked
    },
    {
      headers: {
        needsToken: true
      }
    }
  );
};

export const getChainOfTitleType = () => {
  return api.get(URLS.chainoftitletypes, {
    headers: {
      needsToken: true
    }
  });
};
export const bookmarkChainOfTitleType = (name, isBookmarked) => {
  return api.put(
    URLS.chainoftitletypes,
    {
      name: name,
      bookmark: isBookmarked
    },
    {
      headers: {
        needsToken: true
      }
    }
  );
};
export const getLiensType = () => {
  return api.get(URLS.lienstypes, {
    headers: {
      needsToken: true
    }
  });
};
export const bookmarkLiensType = (name, isBookmarked) => {
  return api.put(
    URLS.lienstypes,
    {
      name: name,
      bookmark: isBookmarked
    },
    {
      headers: {
        needsToken: true
      }
    }
  );
};
export const getRequirementsType = () => {
  return api.get(URLS.requirementstypes, {
    headers: {
      needsToken: true
    }
  });
};
export const bookmarkRequirementsType = (name, isBookmarked) => {
  return api.put(
    URLS.requirementstypes,
    {
      name: name,
      bookmark: isBookmarked
    },
    {
      headers: {
        needsToken: true
      }
    }
  );
};
export const getExceptionsType = () => {
  return api.get(URLS.exceptiontypes, {
    headers: {
      needsToken: true
    }
  });
};
export const bookmarkExceptionsType = (name, isBookmarked) => {
  return api.put(
    URLS.exceptiontypes,
    {
      name: name,
      bookmark: isBookmarked
    },
    {
      headers: {
        needsToken: true
      }
    }
  );
};

export const getJurisdictionsTypes = () => {
  return api.get(URLS.jurisdictionstypes, {
    headers: {
      needsToken: true
    }
  });
};

export const bookmarkJurisdictionsTypes = (name, isBookmarked) => {
  return api.put(
    URLS.jurisdictionstypes,
    {
      name: name,
      bookmark: isBookmarked
    },
    {
      headers: {
        needsToken: true
      }
    }
  );
};

export const saveTypeExamData = (typeExamData) => {
  return api.post(URLS.saveTypeExam, typeExamData, {
    headers: {
      needsToken: true
    }
  });
};

export const updateTypeExamData = (typeExamData) => {
  return api.put(URLS.updateTypeExam, typeExamData, {
    headers: {
      needsToken: true
    }
  });
};

export const getTypeExamDataById = (orderId) => {
  return api.get(`${URLS.typeExam}?id=${orderId}`, {
    headers: {
      needsToken: true
    }
  });
};

export const getNewTaskTitles = () => {
  return api.get(URLS.taskTitles, {
    headers: {
      needsToken: true
    }
  });
};

export const getNewSubTaskTitles = () => {
  return api.get(URLS.subTaskTitles, {
    headers: {
      needsToken: true
    }
  });
};

export const getTaskListByOrderID = (orderID) => {
  return api.get(URLS.tasksList + `?id=${orderID}`, {
    headers: {
      needsToken: true
    }
  });
};

export const addNewTask = (payload, orderID, mainTaskId) => {
  return api.post(
    URLS.tasks + `?orderID=${orderID}${mainTaskId ? `&id=${mainTaskId}` : ''}`,
    payload,
    {
      headers: {
        needsToken: true
      }
    }
  );
};

export const getTeamsList = (searchQuery, pagination) => {
  return api.get(
    `${URLS.teamsName}?${searchQuery ? 'query=' + searchQuery : ''}${
      typeof pagination === 'boolean' ? `&pagination=${pagination}` : ''
    }`,
    {
      headers: {
        needsToken: true
      }
    }
  );
};

export const getMembersList = (teamID, searchQuery, pagination, excludeID) => {
  return api.get(
    `${URLS.members}?${teamID ? `teamID=${teamID}` : ''}${
      searchQuery ? `&query=${searchQuery}` : ''
    }${typeof pagination === 'boolean' ? `&pagination=${pagination}` : ''}${
      excludeID?.length ? `&excludeID=${excludeID}` : ''
    }`,
    {
      headers: {
        needsToken: true
      }
    }
  );
};

export const getTaskMembersList = (teamID, taskID, searchQuery, orderID) => {
  return api.get(
    `${URLS.taskMembers}?${teamID ? `teamID=${teamID}` : ''}${
      taskID ? `&taskID=${taskID}` : ''
    }${searchQuery ? `&query=${searchQuery}` : ''}${
      orderID ? `&orderID=${orderID}` : ''
    }`,
    {
      headers: {
        needsToken: true
      }
    }
  );
};

export const updateTask = (taskId, payload) => {
  return api.put(`${URLS.tasks}?id=${taskId}`, payload, {
    headers: {
      needsToken: true
    }
  });
};

export const deleteTaskByTaskId = (taskId) => {
  return api.delete(`${URLS.tasks}?id=${taskId}`, {
    headers: {
      needsToken: true
    }
  });
};

export const taskSendComment = (taskId, payload) => {
  return api.post(URLS.comments + `?id=${taskId}`, payload, {
    headers: {
      needsToken: true
    }
  });
};

export const getCommentByTaskId = (taskId) => {
  return api.get(URLS.comments + `?id=${taskId}`, {
    headers: {
      needsToken: true
    }
  });
};

export const deleteCommentByCommentId = (commentId, taskID) => {
  return api.delete(`${URLS.comments}?id=${commentId}&taskID=${taskID}`, {
    headers: {
      needsToken: true
    }
  });
};

export const deleteActivityComment = (commentId) => {
  return api.delete(`${URLS.activityComments}?id=${commentId}`, {
    headers: {
      needsToken: true
    }
  });
};

export const updateCommentByCommentId = (commentId, taskID, payload) => {
  return api.put(`${URLS.comments}?id=${commentId}&taskID=${taskID}`, payload, {
    headers: {
      needsToken: true
    }
  });
};

export const getRoles = () => {
  return api.get(URLS.roles, {
    headers: {
      needsToken: true
    }
  });
};

export const getPeoples = () => {
  return api.get(URLS.peoples, {
    headers: {
      needsToken: true
    }
  });
};

export const updatePeople = (peopleID, peopleData) => {
  return api.put(`${URLS.people}?id=${peopleID}`, peopleData, {
    headers: {
      needsToken: true
    }
  });
};

export const deletePeople = (peopleID) => {
  return api.delete(`${URLS.people}?id=${peopleID}`, {
    headers: {
      needsToken: true
    }
  });
};

export const deleteTeam = (teamID) => {
  return api.delete(`${URLS.team}?id=${teamID}`, {
    headers: {
      needsToken: true
    }
  });
};

export const updateTeam = (teamID, teamData) => {
  return api.put(`${URLS.team}?id=${teamID}`, teamData, {
    headers: {
      needsToken: true
    }
  });
};

export const createTeam = (teamData) => {
  return api.post(URLS.team, teamData, {
    headers: {
      needsToken: true
    }
  });
};

export const sendInvitation = (invitationData) => {
  return api.post(URLS.invitation, invitationData, {
    headers: {
      needsToken: true
    }
  });
};

export const getInvitation = (invitationID) => {
  return api.get(`${URLS.invitation}?id=${invitationID}`);
};

export const addUserByInvitation = (userData) => {
  return api.put(URLS.invitation, userData, {
    headers: {
      needsToken: true
    }
  });
};

export const generateDocument = (orderID, templateID) => {
  return api.get(
    `${URLS.generateDocument}?id=${orderID}&templateID=${templateID}`,
    {
      headers: {
        needsToken: true
      }
    }
  );
};

export const getDocumentForTemplate = (orderID, templateID) => {
  return api.get(
    `${URLS.get_document}?id=${orderID}&templateID=${templateID}`,
    {
      headers: {
        needsToken: true
      }
    }
  );
};

export const updateDocument = (orderID, templateID, documentData) => {
  return api.put(
    `${URLS.update_document}?id=${orderID}&templateID=${templateID}`,
    documentData,
    {
      headers: {
        needsToken: true
      }
    }
  );
};

export const searchTaskByOrderID = (orderID, searchQuery) => {
  return api.get(
    `${URLS.searchTask}?id=${orderID}${
      searchQuery ? `&query=${searchQuery}` : ''
    }`,
    {
      headers: {
        needsToken: true
      }
    }
  );
};

export const sendActivityComments = (orderId, payload) => {
  return api.post(URLS.activityComments + `?id=${orderId}`, payload, {
    headers: {
      needsToken: true
    }
  });
};

export const getActivityComments = (orderId) => {
  return api.get(URLS.activityComments + `?id=${orderId}`, {
    headers: {
      needsToken: true
    }
  });
};

export const updateActivityData = (commentId, payload) => {
  return api.put(`${URLS.activityComments}?id=${commentId}`, payload, {
    headers: {
      needsToken: true
    }
  });
};

export const mergePdf = (orderId, formData) => {
  return api.post(`${URLS.mergePdf}?id=${orderId}`, formData, {
    responseType: 'blob',
    headers: {
      needsToken: true
    }
  });
};

export const getAttachmentDocumentsTypes = () => {
  return api.get(URLS.documentsTypes, {
    headers: {
      needsToken: true
    }
  });
};

export const getDocumentsList = (orderId) => {
  return api.get(URLS.documents + `?id=${orderId}`, {
    headers: {
      needsToken: true
    }
  });
};

export const deleteDocument = (fileId, orderID) => {
  return api.delete(URLS.deleteDocument + `?id=${fileId}&orderID=${orderID}`, {
    headers: {
      needsToken: true
    }
  });
};

export const updateDocumentList = (data, fileId) => {
  return api.put(URLS.updateDocument + `?id=${fileId}`, data, {
    headers: {
      needsToken: true
    }
  });
};

export const getDeliverablesTemplateName = () => {
  return api.get(URLS.deliverables, {
    headers: {
      needsToken: true
    }
  });
};

export const getMilestonesTrack = (orderID) => {
  return api.get(`${URLS.milestone}?id=${orderID}`, {
    headers: {
      needsToken: true
    }
  });
};

export const getTaskStatisticsCount = () => {
  return api.get(URLS.getTaskStatistics, {
    headers: {
      needsToken: true
    }
  });
};

export const taskPreviewSortList = () => {
  return api.get(URLS.taskPreviewSortList, {
    headers: {
      needsToken: true
    }
  });
};

export const taskPreviewFilterList = () => {
  return api.get(URLS.taskPreviewFilterList, {
    headers: {
      needsToken: true
    }
  });
};

export const getTaskPreviewAssigneeList = (searchQuery) => {
  return api.get(
    `${URLS.taskPreviewAssigneeList}?${
      searchQuery ? `query=${searchQuery}` : ''
    }`,
    {
      headers: {
        needsToken: true
      }
    }
  );
};

export const getFlowOrderDetails = () => {
  return api.get(URLS.getTaskCompleted, { headers: { needsToken: true } });
};

export const getTaskPreviewDashbaord = (searchQuery, bodydata, page, limit) => {
  return api.post(
    `${URLS.dashboardTasks}?${searchQuery ? `query=${searchQuery}` : ''}${
      page ? `&page=${page}` : ''
    }${limit ? `&limit=${limit}` : ''}`,
    bodydata,
    {
      headers: { needsToken: true }
    }
  );
};

export const getProjectNamesList = (searchQuery) => {
  return api.get(
    `${URLS.getProjectNamesList}?${searchQuery ? `query=${searchQuery}` : ''}`,
    {
      headers: {
        needsToken: true
      }
    }
  );
};

export const getProjectStatsByName = (projectName) => {
  return api.get(`${URLS.getProjectNamesStats}?name=${projectName}`, {
    headers: {
      needsToken: true
    }
  });
};

export const getPartnerProfiles = (serachQuery, limit = 30) => {
  return api.get(
    `${URLS.partnerProfiles}?limit=${limit}${
      serachQuery ? `&query=${serachQuery}` : ''
    }`,
    {
      headers: {
        needsToken: true
      }
    }
  );
};

export const createPartnerProfile = (profileData) => {
  return api.post(URLS.partnerProfile, profileData, {
    headers: {
      needsToken: true
    }
  });
};

export const getPartnerByID = (partnerID) => {
  return api.get(`${URLS.partnerProfile}?id=${partnerID}`, {
    headers: {
      needsToken: true
    }
  });
};

export const updatePartnerProfile = (partnerID, profileData) => {
  return api.put(`${URLS.partnerProfile}?id=${partnerID}`, profileData, {
    headers: {
      needsToken: true
    }
  });
};

export const getPartnerProfileTypes = () => {
  return api.get(URLS.partnerProfileTypes, {
    headers: {
      needsToken: true
    }
  });
};

export const getPartnerContacts = (partnerID) => {
  return api.get(`${URLS.partnerContacts}?id=${partnerID}`, {
    headers: {
      needsToken: true
    }
  });
};

export const createPartnerContact = (partnerID, contactData) => {
  return api.post(
    `${URLS.partnerContact}?partnerId=${partnerID}`,
    contactData,
    {
      headers: {
        needsToken: true
      }
    }
  );
};

export const getPartnerContactByID = (contactID) => {
  return api.get(`${URLS.partnerContact}?id=${contactID}`, {
    headers: {
      needsToken: true
    }
  });
};

export const updatePartnerContact = (partnerID, contactID, contactData) => {
  return api.put(
    `${URLS.partnerContact}?id=${contactID}&partnerId=${partnerID}`,
    contactData,
    {
      headers: {
        needsToken: true
      }
    }
  );
};

export const getPartnerCommunicationsOptions = () => {
  return api.get(URLS.partnerCommunications, {
    headers: {
      needsToken: true
    }
  });
};

export const getClientPurchaseOrderOptions = () => {
  return api.get(URLS.purchaseOrderOptions, {
    headers: {
      needsToken: true
    }
  });
};
export const getClientEmailSubjectFormat = () => {
  return api.get(URLS.emailSubjectFormat, {
    headers: {
      needsToken: true
    }
  });
};

export const getClientProfileDetails = (profileID) => {
  return api.get(`${URLS.clientDetail}?id=${profileID}`, {
    headers: {
      needsToken: true
    }
  });
};

export const updateClientProfile = (profileID, profileData) => {
  return api.put(`${URLS.clientDetail}?id=${profileID}`, profileData, {
    headers: {
      needsToken: true
    }
  });
};

export const getProfileComments = (profileID) => {
  return api.get(`${URLS.partnerComments}?id=${profileID}`, {
    headers: {
      needsToken: true
    }
  });
};

export const sendProfileCommnets = (profileId, payload) => {
  return api.post(URLS.partnerComments + `?id=${profileId}`, payload, {
    headers: {
      needsToken: true
    }
  });
};

export const deleteProfileCommnets = (commentId) => {
  return api.delete(`${URLS.partnerComments}?id=${commentId}`, {
    headers: {
      needsToken: true
    }
  });
};

export const updateProfileCommnets = (commentId, payload) => {
  return api.put(`${URLS.partnerComments}?id=${commentId}`, payload, {
    headers: {
      needsToken: true
    }
  });
};

export const getProductFee = (profileID, serachQuery) => {
  return api.get(
    `${URLS.productFee}?id=${profileID}${
      serachQuery ? `&query=${serachQuery}` : ''
    }`,
    {
      headers: {
        needsToken: true
      }
    }
  );
};

export const updateProductFee = (profileID, feeData) => {
  return api.put(`${URLS.productFee}?id=${profileID}`, feeData, {
    headers: {
      needsToken: true
    }
  });
};

export const enableOrderByOrderID = (orderID) => {
  return api.put(
    `${URLS.orderEnable}?id=${orderID}`,
    {},
    {
      headers: {
        needsToken: true
      }
    }
  );
};

export const cancelOrderByOrderID = (orderID) => {
  return api.delete(
    `${URLS.orderCancel}?id=${orderID}`,

    {
      headers: {
        needsToken: true
      }
    }
  );
};

export const getOrderPreviewSortList = () => {
  return api.get(URLS.orderPreviewSortList, {
    headers: {
      needsToken: true
    }
  });
};

export const getOrderPreviewFilterList = () => {
  return api.get(URLS.orderPreviewFilterList, {
    headers: {
      needsToken: true
    }
  });
};

export const getClientOrderNumbers = (searchQuery) => {
  return api.get(
    `${URLS.clientOrderNumbers}?${searchQuery ? `query=${searchQuery}` : ''}`,
    {
      headers: {
        needsToken: true
      }
    }
  );
};

export const getSiteNames = (searchQuery) => {
  return api.get(
    `${URLS.siteNames}?${searchQuery ? `query=${searchQuery}` : ''}`,
    {
      headers: {
        needsToken: true
      }
    }
  );
};

export const getOrderDashbaordList = (searchQuery, bodydata, page, limit) => {
  return api.post(
    `${URLS.orderDashboard}?${searchQuery ? `query=${searchQuery}` : ''}${
      page ? `&page=${page}` : ''
    }${limit ? `&limit=${limit}` : ''}`,
    bodydata,
    {
      headers: { needsToken: true }
    }
  );
};

export const getRecentVisits = () => {
  return api.get(URLS.getVisits, {
    headers: {
      needsToken: true
    }
  });
};

export const saveVisit = (orderID) => {
  return api.put(
    `${URLS.saveVisit}?id=${orderID}`,
    {},
    {
      headers: {
        needsToken: true
      }
    }
  );
};

export const getOpenLienType = () => {
  return api.get(URLS.openLienType, {
    headers: {
      needsToken: true
    }
  });
};

export const getMiscsType = () => {
  return api.get(URLS.miscsType, {
    headers: {
      needsToken: true
    }
  });
};

export const getLeaseType = () => {
  return api.get(URLS.leaseType, {
    headers: {
      needsToken: true
    }
  });
};

export const getOpenMortgageType = () => {
  return api.get(URLS.openMortgageType, {
    headers: {
      needsToken: true
    }
  });
};

export const toggleBookmarkStatusOpenLien = (name, isBookmarked) => {
  return api.put(
    URLS.toogleBookmarkStatusOpenLien,
    {
      name: name,
      bookmark: isBookmarked
    },
    {
      headers: {
        needsToken: true
      }
    }
  );
};

export const toggleBookmarkStatusMiscs = (name, isBookmarked) => {
  return api.put(
    URLS.toogleBookmarkStatusMiscs,
    {
      name: name,
      bookmark: isBookmarked
    },
    {
      headers: {
        needsToken: true
      }
    }
  );
};

export const toggleBookmarkStatusLease = (name, isBookmarked) => {
  return api.put(
    URLS.toogleBookmarkStatusLease,
    {
      name: name,
      bookmark: isBookmarked
    },
    {
      headers: {
        needsToken: true
      }
    }
  );
};

export const toggleBookmarkStatusOpenMortgage = (name, isBookmarked) => {
  return api.put(
    URLS.toogleBookmarkStatusOpenMortgage,
    {
      name: name,
      bookmark: isBookmarked
    },
    {
      headers: {
        needsToken: true
      }
    }
  );
};

export const getZipCodeAutofill = (zipCode) => {
  return api.post(`${URLS.getZipCodeAutofill}`, null, {
    params: {
      zipCode: zipCode
    },

    headers: {
      needsToken: true
    }
  });
};

export const autofillAddress = (inputSearch) => {
  return api.post(
    URLS.autofillAddress,
    {
      partialAddress: inputSearch
    },
    {
      headers: {
        needsToken: true
      }
    }
  );
};

export const getOrderDetailsTabList = () => {
  return api.get(URLS.orderDetailsTabList, {
    headers: {
      needsToken: true
    }
  });
};

export const getClientMapDetails = () => {
  return api.get(URLS.getClientMap, {
    headers: {
      needsToken: true
    }
  });
};

export const getClientDeliverables = (orderID) => {
  return api.get(URLS.getPreviewDeliverables, {
    params: {
      id: orderID
    },
    headers: {
      needsToken: true
    }
  });
};
